import * as React from "react";
import PropTypes from "prop-types";

class Spacer extends React.Component {
    render() {
        return(
            <div style={{height: this.props.height, width: this.props.width}} />
        )
    }
}

Spacer.defaultProps = {
    height: '0',
    width: '0',
};

Spacer.propTypes = {
    height: PropTypes.string,
    width: PropTypes.string,
};

export default Spacer;