export const personalCards = [
    {
        name: "SystemUI Tuner",
        shortDesc: "Unlock hidden options on your Android device.",
        fullDesc: (`For some reason, a lot of manufacturers seem to have a problem with us customizing our own devices, to the point where some disable the built-in Android System UI Tuner.
                                        
                    This app aims to fix that problem, by providing a replacement. For the most part, this is a replica of Android's System UI Tuner; however, there are a few extra goodies that Android's solution doesn't include by default.
                    
                    Using this app you can:
                    - Control which icons appear in your status bar (not every toggle will work on every device)
                    - Customize Demo Mode
                    - Control the importance level of notifications (7.0+; doesn't work well on Samsung)
                    - Enable some hidden features of Android
                    - Toggle Immersive Mode
                    - Change Quick Settings options (TouchWiz 7.0 users can change the grid size)
                    - Customize animation speeds
                    - And so much more
                    `),
        icon: "/systemuituner.webp",
        background: "/bg_sysuit.webp",
        playLink: "https://play.google.com/store/apps/details?id=com.zacharee1.systemuituner",
        xdaLink: "https://forum.xda-developers.com/android/apps-games/app-systemui-tuner-t3588675",
        githubLink: "https://github.com/zacharee/SystemUITunerRedesign"
    },
    {
        name: "SystemUI Tuner System Settings Add-On",
        shortDesc: "An add-on for System UI Tuner that allows writing to Settings.System without root.",
        fullDesc: (`This is a non-interactive add-on for SystemUI Tuner that allows writing to Settings.System without root or ADB.
                                            
                    Android allows apps targeting Android 5.1 (API 22) or lower to write to Settings.System without restrictions. This add-on takes advantage of this bypass by targeting API 22.
                    `),
        icon: "/systemuituner.webp",
        background: "/bg_sysuit.webp",
        githubLink: "https://github.com/zacharee/SystemUITunerSystemSettings",
        downloadLink: "https://github.com/zacharee/SystemUITunerSystemSettings/releases"
    },
    {
        name: "OneUI Tuner",
        shortDesc: "Customize your Samsung device running One UI.",
        fullDesc: (`Samsung's One UI got a lot of things right, but it isn't perfect. For example, you can no longer do things like enable seconds in the status bar clock or change the QS header count using Android's hidden settings.

                    OneUI Tuner aims to fix these problems by using Samsung's comprehensive theme engine. It's not a theme, though. Instead, OneUI Tuner allows you to do things like setting the QS header icon count, set your own custom clock format, or even restore the old Recents menu from Android Oreo.
                    
                    The best part? This app doesn't need root access. It doesn't even need anything to be done through ADB. 
                    - On 9th generation devices, applying the options from a section will prompt you to install an app. Install it again or reboot for the changes to fully apply.
                    - On 8th generation devices, if you aren't using Synergy, apply your options in each section and then hit the "Send" button (the middle one) to install the Samsung theme.
                    - On 8th generation devices, if you are using Synergy, applying your options will import that section's theme into Synergy.
                    
                    Current features:
                    - Custom status bar clock format (color will not change automatically; there is a shadow around the text to make it visible on light backgrounds)
                    - Custom QS shade date format
                    - Custom QS header counts for portrait and landscape
                    - Custom QS row/column counts for portrait and landscape
                    - Toggle QS tile backgrounds
                    - Move system icons to the left of the status bar
                    - Hide the status bar carrier label
                    - Toggle lock screen rotation
                    - Toggle the old Android Oreo-style Recents menu (doesn't work with Samsung's gestures)
                    - Set the navigation bar height
                    - Set the status bar height
                    `),
        icon: "/oneuituner.webp",
        background: "/bg_ouit.webp",
        patreonLink: "https://patreon.com/zacharywander",
        xdaLink: "https://forum.xda-developers.com/general/paid-software/app-oneui-tuner-t3903945",
        githubLink: "https://github.com/zacharee/OneUITunerRedesign"
    },
    {
        name: "HomeCalc",
        shortDesc: "Do simple calculations right from your home screen.",
        fullDesc: (`Ever get tired of opening up the calculator app every time you need to do a simple operation? Do you want to be able to do multiple calculations at once?

                    HomeCalc lets you do both. This app is a simple home screen widget, which allows you to make basic calculations without opening any apps. You can even add multiples of it, and have as many calculators on your home screen as you want!
                    
                    Simply add the widget to your home screen to get started.
                    `),
        icon: "/homecalc.webp",
        background: "/bg_hc.webp",
        playLink: "https://play.google.com/store/apps/details?id=com.zacharee1.calculatorwidget",
        xdaLink: "https://forum.xda-developers.com/android/apps-games/widget-homecalc-calculator-widget-t3838017",
        githubLink: "https://github.com/zacharee/CalculatorWidget"
    },
    {
        name: "Insomnia",
        shortDesc: "Keep your display on when you want.",
        fullDesc: (`Insomnia is essentially a remake of LineageOS' Caffeine Tile, but with a few extra features.

                    Caffeine is a feature that allows users to force their device's display to remain on for longer than the currently set timeout, but without actually changing that timeout. If the display is manually turned off, Caffeine deactivates automatically.
                    
                    Insomnia works on the same principle. Through the use of a Quick Settings Tile, you can use Insomnia to cycle through predefined screen timeout overrides: 1, 5, 10, 30 and infinite minutes. However, if you want to set your own timeout (say 1 or 2 hours), or you want to remove some of the predefined timeouts, you can do that!
                    
                    Simply long-press on the added Tile and Insomnia's configuration will appear.
                    
                    Insomnia doesn't need root or even ADB commands; it barely needs any set up at all. If you download from the Play Store, it's completely plug-and-play. Just add the Tile to your Quick Settings and activate it!
                    `),
        icon: "/insomnia.webp",
        background: "/bg_insomnia.webp",
        playLink: "https://play.google.com/store/apps/details?id=com.zacharee1.insomnia",
        xdaLink: "https://forum.xda-developers.com/general/paid-software/android-7-0-insomnia-t3831416",
        patreonLink: "https://patreon.com/zacharywander",
        githubLink: "https://github.com/zacharee/Insomnia"
    },
    {
        name: "Nacho Notch",
        shortDesc: "Hide your Android's notch or cutout.",
        fullDesc: (`Maybe you have a phone with a notch, maybe you don't. If you do, you might also be annoyed by having this black blob in the middle of a light-colored status bar most of the time.

                    If this description fits you, you probably want this app. Nacho Notch colors the status bar black while the device is in the portrait orientation, making it better fit with the notch (thereby "hiding" it).
                    
                    Even if you don't have a notch, but still want a black status bar, you can use the app too. It dynamically finds the height of your statusbar, so you won't be dealing with too little or too much height, or finnicky manual sizing.
                    `),
        icon: "/nachonotch.webp",
        background: "/bg_nn.webp",
        playLink: "https://play.google.com/store/apps/details?id=com.xda.nachonotch",
        githubLink: "https://github.com/zacharee/NachoNotch"
    },
    {
        name: "Widget Drawer",
        shortDesc: "Access your widgets from almost anywhere.",
        fullDesc: (`Do you use widgets frequently? Are you maybe tired of having to go to the home screen just to take a quick glance at your inbox? You could just open the widget's app, but then you have to wait for the app to open and load.

                    Widget Drawer aims to take care of this convenience issue by adding a way for you to access your widgets from nearly any app or screen.
                    
                    When enabled, Widget Drawer will add a handle to the side of your screen. Simply swipe that handle inward and you will be presented with an area to add widgets. This handle will stay on the screen whether you're on the home screen, browsing the internet, or reading a book.
                    
                    Widget Drawer also provides certain customization options for the drawer itself:
                    
                    - Change the height of each widget
                    - Set whether a widget should take up 1 or 2 columns in width
                    - Reorder widgets
                    - Choose whether or not widgets should have an opaque background
                    
                    The handle has a few options as well. It can be moved vertically or even to the other side of the screen by long-pressing it and then dragging. The size and color can even be personalized from within the app itself.
                    `),
        icon: "/widgetdrawer.webp",
        background: "/bg_wd.webp",
        playLink: "https://play.google.com/store/apps/details?id=tk.zwander.widgetdrawer",
        xdaLink: "https://forum.xda-developers.com/general/paid-software/app-widget-drawer-t3866671",
        githubLink: "https://github.com/zacharee/WidgetDrawer",
        patreonLink: "https://patreon.com/zacharywander"
    },
    {
        name: "OPFPControl",
        shortDesc: "Theme the fingerprint scanner icon on supported OnePlus devices.",
        fullDesc: (`Make use of Android's Runtime Resource Overlay system to replace the default in-display fingerprint scanner icon on OnePlus devices.
        
                    OPFPControl should be compatible with all rooted OnePlus devices that have an in-display fingerprint reader and a supported ROM (OxygenOS or other firmware with the same resource names).
                    `),
        icon: "/opfpcontrol.webp",
        background: "/bg_opfpcontrol.webp",
        xdaLink: "https://forum.xda-developers.com/oneplus-6t/themes/app-theme-opfpcontrol-custom-t3899522",
        githubLink: "https://github.com/zacharee/OPFPControl"
    },
    {
        name: "Overlay Manager",
        shortDesc: "Manage installed overlays.",
        fullDesc: (`Overlay Manager is a simple app to let you easily manage installed RROs (runtime resource overlays). Examples of RROs include Substratum themes and OPFPControl's module.

                    With Overlay Manager you can:
                    - View all installed overlays, sorted by target app
                    - View package names of specific overlays
                    - View priorities of specific overlays
                    - Search for a specific app or overlay
                    - Enable/disable specific overlays
                    - Enable/disable all installed overlays
                    - Set specific overlays to highest/lowest priority
                    `),
        icon: "/overlaymanager.webp",
        background: "/bg_om.webp",
        patreonLink: "https://patreon.com/zacharywander",
        githubLink: "https://github.com/zacharee/OverlayManager"
    },
    {
        name: "Root Activity Launcher",
        shortDesc: "Launch Activities and Services using Root.",
        fullDesc: (`There are a few Activity launchers on the Play Store, but none quite like this one.

                    The other launchers only let you launch enabled, exported, and permission-free Activities. Even if you're rooted, they don't let you start hidden Activities. That's where Root Activity Launcher comes in.
                    
                    Not only can you use root to start unexported Activities, or Activities with permission requirements, but you can also start Services and Broadcast Receivers. As if that weren't enough, Root Activity Launcher also lets you use root to easily enable/disable Activities and Services, and you can even specify extras to pass in the launch intent.
                    
                    You can also filter components by their state: enabled/disabled, exported/unexported.
                    
                    Launching hidden Activities and Services tends to require root. There's no way around that, unfortunately. However, if you don't have root, you can still enjoy the clean interface and the ability to pass extras to Activities, Services, and Receivers you are able to launch.
                    `),
        icon: "/ral.webp",
        background: "/bg_ral.webp",
        playLink: "https://play.google.com/store/apps/details?id=tk.zwander.rootactivitylauncher",
        githubLink: "https://github.com/zacharee/RootActivityLauncher",
        patreonLink: "https://patreon.com/zacharywander"
    },
    {
        name: "Lockscreen Widgets",
        shortDesc: "Put widgets on your lock screen!",
        fullDesc: (`A (very) long time ago, Android introduced a feature to allow you to show certain widgets on the lock screen. For some reason, this useful feature was removed with the release of Android 5.0 Lollipop, confining widgets to the home screen only.

                    While some manufacturers, such as Samsung, have brought back limited versions of lockscreen widgets, but you're usually limited to the widgets the manufacturer has already created for you.
                    
                    Well, no more! Lockscreen Widgets brings back the functionality of yore, with some extra features.
                    
                    - Lockscreen Widgets appears as a paged "frame" on top of your lockscreen.
                    - Add a widget by tapping the plus button in the frame. This plus button will always be the last page.
                    - Each widget you add gets its own page.
                    - You can press, hold, and drag a widget left or right to reorder it.
                    - Tap the frame with two fingers to enter the editing mode: you resize and move the frame, and remove the current widget here.
                    - Tap the frame with three fingers to temporarily hide it. It will show up again once the display turns off and back on.
                    - Any home screen widget can be added as a lockscreen widget.
                    
                    Inside the app, there are a few settings you can change:
                    - You can make the widget frame's background opaque if you want to overlay lockscreen elements.
                    - You can have the widget frame hide when there are notifications displayed on the lockscreen.
                    - You can have the widget frame hide when the notification shade is down. This probably won't work on heavily customized skins like EMUI or MIUI.
                    - You can have the widget frame hide when the security input view is shown (PIN/pattern/password/etc). This probably won't work on heavily customized skins like EMUI or MIUI.
                    
                    And this is all without ADB or root! All the necessary privileges can be granted without even thinking about using a computer.
                    `),
        icon: "/lswidg.webp",
        background: "/bg_lswidg.webp",
        playLink: "https://play.google.com/store/apps/details?id=tk.zwander.lockscreenwidgets",
        githubLink: "https://github.com/zacharee/LockscreenWidgets",
        patreonLink: "https://patreon.com/zacharywander"
    },
    {
        name: "Rebooter",
        shortDesc: "Reboot in style.",
        fullDesc: (`Ever wanted to reboot? Ever wanted to reboot into Recovery or Fastboot?

                    Well the first one is usually pretty easy. Just hold down the power button and tap "Restart". But what about the second one?
                    
                    Well if you're lucky, your phone's manufacturer may have natively implemented advanced reboot options, but most aren't so lucky. That's where Rebooter comes in.
                    
                    Rebooter is a super simple app that lets you reboot. On top of the basic power options like Shut Down, Restart, and Safe Mode, you can also reboot to Recovery, Fastboot, Fastbootd, and Download Mode. If that isn't enough, you can even restart System UI or do a quick reboot.
                    
                    Don't use one or more of these reboot options? No problem. You can reorder, remove, and re-add buttons.
                    
                    Of course, there's a caveat here. Because of permission restrictions in Android, Rebooter requires root access to work.
                    
                    NOTE: On Samsung devices, the menu background will be blurred. This feature only works on Samsung devices running Samsung software! Other devices and operating systems will show a dimmed transparent background.
                    
                    Rebooter is built with themers in mind. Things like dimensions and colors are extracted to individual resources, which should make it much simpler to theme.
                    `),
        icon: "/rebooter.webp",
        background: "/bg_rebooter.webp",
        playLink: "https://play.google.com/store/apps/details?id=tk.zwander.rebooter",
        githubLink: "https://github.com/zacharee/Rebooter",
        patreonLink: "https://patreon.com/zacharywander"
    },
    {
        name: "SPRViewer",
        shortDesc: "View and extract other apps' resources.",
        fullDesc: (`SPRViewer is a utility app to allow extracting resources from other apps. 
                                            
                    Originally made to view and export SPR-format images from Samsung devices, SPRViewer can view and extract XML vectors, raster images, and even some animations and selectors. Image support depends on what your device supports (i.e., you can't view SPRs on a OnePlus device).
                    
                    On top of that, you can now view and extract String resources.
                    `),
        icon: "/sprviewer.webp",
        background: "/bg_sprviewer.webp",
        amazonLink: "https://www.amazon.com/Zachary-Wander-SPRViewer/dp/B08SGH4TTD",
        githubLink: "https://github.com/zacharee/SPRViewer"
    },
    {
        name: "Samsung Firmware Downloader",
        shortDesc: "Easily download firmware for your Samsung device.",
        fullDesc: (`This is yet another firmware downloader for Samsung devices, but it has some special features.

                    For one, it's cross-platform. Samsung Firmware Downloader runs on Windows, Linux, macOS, and even Android!
                    
                    Samsung Firmware Downloader is also a graphical program, with a shared UI across all supported platforms.
                    
                    Most of the functionality in Samsung Firmware Downloader is based on <a href='https://github.com/nlscc/samloader'>Samloader</a>. The Python code has been converted to Kotlin and tweaked to take advantage of some of Kotlin's features.
                    
                    Samsung Firmware Downloader uses Jetpack Compose, JetBrains Compose for Desktop, and Kotlin Multiplatform to create a shared codebase for all supported platforms.
                    `),
        icon: "/samloader.webp",
        background: "/bg_samloader.webp",
        githubLink: "https://github.com/zacharee/SamloaderKotlin",
        downloadLink: "https://github.com/zacharee/SamloaderKotlin/releases"
    }
].sort((first, second) => first.name.localeCompare(second.name))

export const xdaCards = [
    {
        name: "One-Handed Mode",
        shortDesc: "Use your phone with one hand!",
        fullDesc: (`One-Handed Mode by XDA-Developers is the first and only app that replicates the iOS Reachability feature without requiring root access or a custom ROM.

                    One-Handed Mode lets you reach your status bar and action bar of any app without having to stretch your hand. It does this by moving the entire screen down to a smaller window.
                    
                    This app uses a floating, movable bubble that toggles One-Handed Mode whenever it is pressed. There are also other ways to toggle One-Handed Mode if you dislike the bubble.
                    `),
        icon: "/ohm.webp",
        background: "/bg_ohm.webp",
        xdaLink: "https://forum.xda-developers.com/android/apps-games/official-xda-one-handed-mode-phone-one-t3695602"
    },
    {
        name: "Navigation Gestures",
        shortDesc: "Use gestures to navigate Android.",
        fullDesc: (`Jealous of the cool new gesture navigation features on newer phones?
                    With Navigation Gestures, you don't have to buy a new phone or wait for the next Android update to get swipe gesture controls. Navigation Gestures brings swipe gestures to any Android device! Replace the navigation bar buttons and get gesture controls with Navigation Gestures!
                    
                    Best of all, Navigation Gestures can completely hide the stock navigation bar
                    without root! This is the first gesture control app with this feature.
                    
                    Swipe left, right, up, or down. Swipe up and hold. Tap. Double tap. Long press. Go crazy and split the pill in 3! There's a lot of ways you can use the gesture bar to control your phone. Don’t like how the gesture pill looks? Customize it!
                    `),
        icon: "/navgest.webp",
        background: "/bg_ng.webp",
        xdaLink: "https://forum.xda-developers.com/android/apps-games/official-xda-navigation-gestures-iphone-t3792361"
    },
    {
        name: "Sa2ration",
        shortDesc: "Change your saturation.",
        fullDesc: (`Sa2ration is a super simple app to change the saturation on your rooted Android Oreo device. It is confirmed to be functional on both the Pixel 1 and Pixel 2, and will likely work on other Oreo devices, as well.

                    Features:
                    - Change the saturation level of your Android Oreo device.
                    - Toggle sRGB colorspace.
                    `),
        icon: "/sa2ration.webp",
        background: "/bg_s2.webp",
        githubLink: "https://github.com/zacharee/Sa2ration"
    }
].sort((first, second) => first.name.localeCompare(second.name))