/*!

=========================================================
* Material Kit React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Hidden from "@material-ui/core/Hidden";
import Drawer from "@material-ui/core/Drawer";
// @material-ui/icons
import Menu from "@material-ui/icons/Menu";
// core components
import headerStyle from "assets/jss/material-kit-react/components/headerStyle.jsx";
import * as React from "react";
import {createRef} from "react";

class Header extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            mobileOpen: false
        };

        this.ref = createRef();
    }

    componentDidMount() {
        this.props.onHeaderResize(this.ref.current.clientHeight);
    }

    handleDrawerToggle = () => {
        this.setState({mobileOpen: !this.state.mobileOpen});
    };
    headerColorChange = () => {
        if (this.props.changeColorOnScroll) {
            const {classes, color, changeColorOnScroll} = this.props;
            const windowsScrollTop = this.props.scrollBar.getScrollTop();
            if (windowsScrollTop > changeColorOnScroll.height) {
                document.body
                    .getElementsByTagName("header")[0]
                    .classList.remove(classes[color]);
                document.body
                    .getElementsByTagName("header")[0]
                    .classList.add(classes[changeColorOnScroll.color]);
            } else {
                document.body
                    .getElementsByTagName("header")[0]
                    .classList.add(classes[color]);
                document.body
                    .getElementsByTagName("header")[0]
                    .classList.remove(classes[changeColorOnScroll.color]);
            }
        }
    };

    onBrandClick = () => {
        const scrollBar = this.props.scrollBar;
        // window.scrollTo({top: 0, behavior: 'smooth'})

        const c = scrollBar ? scrollBar.getScrollTop() : 0;
        if (c > 0) {
            window.requestAnimationFrame(() => this.onBrandClick());
            scrollBar.scrollTop(c - c / 8);

        }
    };

    render() {
        const {
            classes,
            color,
            rightLinks,
            leftLinks,
            brand,
            fixed,
            absolute
        } = this.props;
        const appBarClasses = classNames({
            [classes.appBar]: true,
            [classes[color]]: color,
            [classes.absolute]: absolute,
            [classes.fixed]: fixed
        });
        const brandComponent = <div>
            <Button
                onClick={this.onBrandClick}
                className={classes.title}>
                {brand}
            </Button>
        </div>;
        return (
            <AppBar className={appBarClasses} ref={this.ref}>
                <Toolbar className={classes.container}>
                    {leftLinks !== undefined ? brandComponent : null}
                    <div className={classes.flex}>
                        {leftLinks !== undefined ? (
                            <Hidden smDown implementation="css">
                                {leftLinks}
                            </Hidden>
                        ) : (
                            brandComponent
                        )}
                    </div>
                    <Hidden smDown implementation="css">
                        {rightLinks}
                    </Hidden>
                    <Hidden mdUp>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={this.handleDrawerToggle}
                        >
                            <Menu/>
                        </IconButton>
                    </Hidden>
                </Toolbar>
                <Hidden mdUp implementation="js">
                    <Drawer
                        variant="temporary"
                        anchor={"right"}
                        open={this.state.mobileOpen}
                        classes={{
                            paper: classes.drawerPaper
                        }}
                        onClose={this.handleDrawerToggle}
                    >
                        <div className={classes.appResponsive}>
                            {leftLinks}
                            {rightLinks}
                        </div>
                    </Drawer>
                </Hidden>
            </AppBar>
        );
    }
}

Header.defaultProp = {
    color: "white"
};

Header.propTypes = {
    classes: PropTypes.object.isRequired,
    color: PropTypes.oneOf([
        "primary",
        "info",
        "success",
        "warning",
        "danger",
        "transparent",
        "white",
        "rose",
        "dark"
    ]),
    rightLinks: PropTypes.node,
    leftLinks: PropTypes.node,
    brand: PropTypes.string,
    fixed: PropTypes.bool,
    absolute: PropTypes.bool,
    scrollBar: PropTypes.object,
    // this will cause the sidebar to change the color from
    // this.props.color (see above) to changeColorOnScroll.color
    // when the window.pageYOffset is heigher or equal to
    // changeColorOnScroll.height and then when it is smaller than
    // changeColorOnScroll.height change it back to
    // this.props.color (see above)
    changeColorOnScroll: PropTypes.shape({
        height: PropTypes.number.isRequired,
        color: PropTypes.oneOf([
            "primary",
            "info",
            "success",
            "warning",
            "danger",
            "transparent",
            "white",
            "rose",
            "dark"
        ]).isRequired
    }),
    onHeaderResize: PropTypes.func
};

export default withStyles(headerStyle)(Header);
