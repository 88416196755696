/*!

=========================================================
* Material Kit React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
// react components for routing our app without refresh
import {Link} from "react-router-dom";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";

// @material-ui/icons
import {Apps, CloudDownload} from "@material-ui/icons";

// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.jsx";
import Button from "components/CustomButtons/Button.jsx";

import headerLinksStyle from "assets/jss/material-kit-react/components/headerLinksStyle.jsx";
import Image from "react-bootstrap/Image";

import emailAddress from '../EmailAddress';

function HeaderLinks({...props}) {
    const {classes} = props;
    return (
        <List className={classes.list}>
            {/*<ListItem className={classes.listItem}>*/}
            {/*<CustomDropdown*/}
            {/*  noLiPadding*/}
            {/*  buttonText="Components"*/}
            {/*  buttonProps={{*/}
            {/*    className: classes.navLink,*/}
            {/*    color: "transparent"*/}
            {/*  }}*/}
            {/*  buttonIcon={Apps}*/}
            {/*  dropdownList={[*/}
            {/*    <Link to="/" className={classes.dropdownLink}>*/}
            {/*      All components*/}
            {/*    </Link>,*/}
            {/*    <a*/}
            {/*      href="https://creativetimofficial.github.io/material-kit-react/#/documentation?ref=mkr-navbar"*/}
            {/*      target="_blank"*/}
            {/*      className={classes.dropdownLink}*/}
            {/*    >*/}
            {/*      Documentation*/}
            {/*    </a>*/}
            {/*  ]}*/}
            {/*/>*/}
            {/*</ListItem>*/}
            {/*<ListItem className={classes.listItem}>*/}
            {/*  <Button*/}
            {/*    href="https://www.creative-tim.com/product/material-kit-react?ref=mkr-navbar"*/}
            {/*    color="transparent"*/}
            {/*    target="_blank"*/}
            {/*    className={classes.navLink}*/}
            {/*  >*/}
            {/*    <CloudDownload className={classes.icons} /> Download*/}
            {/*  </Button>*/}
            {/*</ListItem>*/}
            <ListItem className={classes.listItem}>
                {/*<Tooltip title="Delete">
          <IconButton aria-label="Delete">
            <DeleteIcon />
          </IconButton>
        </Tooltip>*/}
            </ListItem>
            <ListItem className={classes.listItem}>
                <Tooltip
                    id="linkedin"
                    title="Connect on LinkedIn"
                    placement={window.innerWidth > 959 ? "top" : "left"}
                    classes={{tooltip: classes.tooltip}}
                >
                    <Button
                        color="transparent"
                        href="https://www.linkedin.com/in/zachary-wander-53b862145/"
                        target="_blank"
                        className={classes.navLink}
                    >
                        <i className={classes.socialIcons + " fab fa-linkedin"}/>
                    </Button>
                </Tooltip>
            </ListItem>

            <ListItem className={classes.listItem}>
                <Tooltip
                    id="github"
                    title="GitHub"
                    placement={window.innerWidth > 959 ? "top" : "left"}
                    classes={{tooltip: classes.tooltip}}
                >
                    <Button
                        color="transparent"
                        href="https://github.com/zacharee/"
                        target="_blank"
                        className={classes.navLink}
                    >
                        <i className={classes.socialIcons + " fab fa-github"}/>
                    </Button>
                </Tooltip>
            </ListItem>

            <ListItem className={classes.listItem}>
                <Tooltip
                    id="patreon"
                    title="Support me on Patreon"
                    placement={window.innerWidth > 959 ? "top" : "left"}
                    classes={{tooltip: classes.tooltip}}
                >
                    <Button
                        color="transparent"
                        href="https://patreon.com/zacharywander"
                        target="_blank"
                        className={classes.navLink}
                    >
                        <i className={classes.socialIcons + " fab fa-patreon"}/>
                    </Button>
                </Tooltip>
            </ListItem>

            <ListItem className={classes.listItem}>
                <Tooltip
                    id="twitter"
                    title="Twitter"
                    placement={window.innerWidth > 959 ? "top" : "left"}
                    classes={{tooltip: classes.tooltip}}
                >
                    <Button
                        color="transparent"
                        href="https://twitter.com/Wander1236"
                        target="_blank"
                        className={classes.navLink}
                    >
                        <i className={classes.socialIcons + " fab fa-twitter"}/>
                    </Button>
                </Tooltip>
            </ListItem>

            <ListItem className={classes.listItem}>
                <Tooltip
                    id="mastodon"
                    title="Mastodon"
                    placement={window.innerWidth > 959 ? "top" : "left"}
                    classes={{tooltip: classes.tooltip}}
                >
                    <Button
                        color="transparent"
                        href="https://androiddev.social/@wander1236"
                        rel="me"
                        target="_blank"
                        className={classes.navLink}
                    >
                        <i className={classes.socialIcons + " fab fa-mastodon"}/>
                    </Button>
                </Tooltip>
            </ListItem>

            <ListItem className={classes.listItem}>
                <Tooltip
                    id="playstore"
                    title="Google Play"
                    placement={window.innerWidth > 959 ? "top" : "left"}
                    classes={{tooltip: classes.tooltip}}
                >
                    <Button
                        color="transparent"
                        href="http://play.google.com/store/apps/dev?id=6168495537212917027"
                        target="_blank"
                        className={classes.navLink}
                    >
                        <i className={classes.socialIcons + " fab fa-google-play"}/>
                    </Button>
                </Tooltip>
            </ListItem>

            <ListItem className={classes.listItem}>
                <Tooltip
                    id="contact"
                    title="Contact"
                    placement={window.innerWidth > 959 ? "top" : "left"}
                    classes={{tooltip: classes.tooltip}}
                >
                    <Button
                        color="transparent"
                        href={`mailto:${emailAddress()}`}
                        target="_blank"
                        className={classes.navLink}
                    >
                        <i className={classes.socialIcons + " far fa-envelope"}/>
                    </Button>
                </Tooltip>
            </ListItem>

            <ListItem className={classes.listItem}>
                <Tooltip
                    id="discord"
                    title="Join my Discord server"
                    placement={window.innerWidth > 959 ? "top" : "left"}
                    classes={{tooltip: classes.tooltip}}
                >
                    <Button
                        color="transparent"
                        href={`https://discord.gg/dv7CgR6JSz`}
                        target="_blank"
                        className={classes.navLink}
                    >
                        <i className={classes.socialIcons + " fab fa-discord"}/>
                    </Button>
                </Tooltip>
            </ListItem>
        </List>
    );
}

export default withStyles(headerLinksStyle)(HeaderLinks);
