/**
 * @return {string}
 */
export default function emailAddress() {
    const coded = "G8Hd8vS@GB8etXv.tXo";
    const key = "sg9wF2pS31jauXAtJ6QLmrBif7NKcz8D5VC4qnRhvPWZYTMHGkyxl0IUebdoEO";
    const shift = coded.length;
    let link="";
    for (let i = 0; i < coded.length; i++) {
        if (key.indexOf(coded.charAt(i)) === -1) {
            const ltr = coded.charAt(i);
            link += (ltr)
        } else {
            const ltr = (key.indexOf(coded.charAt(i)) - shift + key.length) % key.length;
            link += (key.charAt(ltr))
        }
    }

    return link
}