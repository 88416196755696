import {backgroundColor} from "./material-kit-react";
import {createTheme} from "@material-ui/core/styles";

export default createTheme({
    palette: {
        type: 'dark',
        background: backgroundColor,
    },
    overrides: {
        MuiPaper: {
            root: {
                backgroundColor: backgroundColor,
                background: backgroundColor,
            },
        },
    },
})
