import * as React from "react";
import {Typography} from "@material-ui/core";
import PropTypes from "prop-types";
import Spacer from "../Space/Spacer";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Image from "react-bootstrap/Image";
import Icon from "@material-ui/core/Icon";
import {loadCSS} from 'fg-loadcss';
import CardActionArea from "@material-ui/core/CardActionArea";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import withStyles from "@material-ui/styles/withStyles";
import IconButton from "@material-ui/core/IconButton";
import DialogActions from "@material-ui/core/DialogActions";
import cardBG from "../../assets/img/bg2.webp";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import VisibilitySensor from "react-visibility-sensor";

const style = theme => ({
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    cardHidden: {
        opacity: "0",
        transform: "translate3d(0, -60px, 0)",
    },
    cardShown: {
        transition: 'opacity 0.5s',
        opacity: "1",
    },
    cardTitle: {
        textAlign: 'center',
    },
    card: {
        marginBottom: "2em",
        marginTop: "2em",
    },
});

class ProjectCard extends React.Component {
    constructor(props) {
        super(props);

        this.dialogUrl = 'dialog-' + this.props.name.replace(/\s/g, '').toLowerCase();
        this.state = {
            dialogOpen: false,
            cardShown: false,
        };

        loadCSS(
            'https://use.fontawesome.com/releases/v5.1.0/css/all.css',
            document.querySelector('#font-awesome-css'),
        );
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        const query = window.location.search.replace("?", "")
        const param = this.props.match.params[0]
        const path = window.location.pathname.replace("/", "")

        if (this.dialogUrl === path || this.dialogUrl === param || this.dialogUrl === query) {
            if (!this.state.dialogOpen) {
                this.setState({
                    dialogOpen: true,
                })
            }
        } else {
            if (this.state.dialogOpen) {
                this.setState({
                    dialogOpen: false,
                })
            }
        }
    }

    onVisibilityChange(visible) {
        if (visible) {
            if (!this.state.cardShown) {
                this.setState({ cardShown: true })
            }
        }
    }

    render() {
        const {classes, background} = this.props;

        return (
            <VisibilitySensor
                onChange={(visible) => this.onVisibilityChange(visible)}>
                <div style={{
                    height: '95%',
                }}>
                    <Card
                        className={classes.card + " " + (this.state.cardShown === true ? classes.cardShown : classes.cardHidden)}
                        style={{
                            height: 'inherit',
                            background: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${background ? background : cardBG})`,
                            backgroundSize: '100% 100%',
                            backgroundRepeat: "no-repeat",
                        }}>
                        <CardActionArea
                            onClick={() => {
                                this.setState({
                                    dialogOpen: true
                                });
                                window.history.pushState(null, null, this.dialogUrl)
                            }}
                            style={{
                                textAlign: 'center',
                                height: '100%',
                            }}>
                            <div
                                style={{
                                    display: 'inline-block',
                                }}>
                                <img
                                    alt={this.props.name}
                                    height={"125"}
                                    width={"125"}
                                    src={this.props.icon}
                                    style={{
                                        borderRadius: "0.7em",
                                    }}
                                />
                                <CardContent style={{
                                    textAlign: 'center',
                                    height: 'inherit',
                                }}>
                                    <Typography
                                        variant={"h4"}
                                        className={classes.cardTitle}>
                                        {this.props.name}
                                    </Typography>
                                    <Spacer
                                        height={"1em"}
                                    />
                                    <Typography
                                        variant={"body2"}>
                                        {this.props.shortDesc}
                                    </Typography>
                                </CardContent>
                            </div>
                        </CardActionArea>
                    </Card>
                    <Dialog
                        onClose={() => {
                            this.setState({ dialogOpen: false });
                            window.history.replaceState(null, null, '/');
                            this.props.match.params[0] = "";
                        }}
                        aria-labelledby="simple-dialog-title"
                        scroll={"paper"}
                        open={this.state.dialogOpen}>
                        <DialogTitle disableTypography id="simple-dialog-title">
                            <Typography variant={"h6"}>{this.props.name}</Typography>
                            <IconButton aria-label="close" className={classes.closeButton}
                                        onClick={() => {
                                            this.setState({ dialogOpen: false });
                                            window.history.replaceState(null, null, '/');
                                            this.props.match.params[0] = "";
                                        }}>
                                <Icon>close</Icon>
                            </IconButton>
                        </DialogTitle>
                        <DialogContent>
                            <Typography
                                variant={"body2"}
                                style={{whiteSpace: 'pre-line'}}>
                                {this.props.fullDesc}
                            </Typography>
                            <br/>
                        </DialogContent>
                        <DialogActions>
                            <Container fluid>
                                <Row
                                    style={{
                                        textAlign: 'center',
                                    }}>
                                    {
                                        this.props.playLink != null ? (
                                            <Col xs>
                                                <Button
                                                    className={classes.navLink}
                                                    href={this.props.playLink}
                                                    target={'_blank'}>
                                                    <Icon
                                                        className={classes.socialIcons + ' fab fa-google-play'}
                                                        style={{
                                                            fontSize: 25,
                                                        }}/>
                                                </Button>
                                            </Col>
                                        ) : ''
                                    }
                                    {
                                        this.props.amazonLink != null ? (
                                            <Col xs>
                                                <Button
                                                    className={classes.navLink}
                                                    href={this.props.amazonLink}
                                                    target={'_blank'}>
                                                    <Icon
                                                        className={classes.socialIcons + ' fab fa-amazon'}
                                                        style={{
                                                            fontSize: 25,
                                                        }}/>
                                                </Button>
                                            </Col>
                                        ) : ''
                                    }
                                    {
                                        this.props.patreonLink != null ? (
                                            <Col xs>
                                                <Button
                                                    className={classes.navLink}
                                                    href={this.props.patreonLink}
                                                    target={'_blank'}>
                                                    <Icon
                                                        className={classes.socialIcons + ' fab fa-patreon'}
                                                        style={{
                                                            fontSize: 25,
                                                        }} />
                                                </Button>
                                            </Col>
                                        ) : ''
                                    }
                                    {
                                        this.props.xdaLink != null ? (
                                            <Col xs>
                                                <Button
                                                    className={classes.navLink}
                                                    href={this.props.xdaLink}
                                                    target={'_blank'}>
                                                    <Image src={'/xda.webp'} height={30}/>
                                                </Button>
                                            </Col>
                                        ) : ''
                                    }
                                    {
                                        this.props.githubLink != null ? (
                                            <Col xs>
                                                <Button
                                                    className={classes.navLink}
                                                    href={this.props.githubLink}
                                                    target={'_blank'}>
                                                    <Icon
                                                        className={classes.socialIcons + ' fab fa-github'}
                                                        style={{
                                                            fontSize: 30,
                                                        }}/>
                                                </Button>
                                            </Col>
                                        ) : ''
                                    }
                                    {
                                        this.props.downloadLink != null ? (
                                            <Col xs>
                                                <Button
                                                    className={classes.navLink}
                                                    href={this.props.downloadLink}
                                                    target={'_blank'}>
                                                    <Icon
                                                        className={classes.socialIcons + ' fas fa-download'}
                                                        style={{
                                                            fontSize: 30,
                                                        }}/>
                                                </Button>
                                            </Col>
                                        ) : ''
                                    }
                                </Row>
                            </Container>
                        </DialogActions>
                    </Dialog>
                </div>
            </VisibilitySensor>
        )
    }
}

ProjectCard.propTypes = {
    classes: PropTypes.object.isRequired,
    name: PropTypes.string,
    shortDesc: PropTypes.string,
    fullDesc: PropTypes.string,
    playLink: PropTypes.string,
    patreonLink: PropTypes.string,
    xdaLink: PropTypes.string,
    githubLink: PropTypes.string,
    downloadLink: PropTypes.string,
    amazonLink: PropTypes.string,
    icon: PropTypes.string,
    background: PropTypes.string,
    match: PropTypes.any
};

export default withStyles(style)(ProjectCard);