/*!

=========================================================
* Material Kit React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import parallaxStyle from "assets/jss/material-kit-react/components/parallaxStyle.jsx";
class Parallax extends React.Component {
    constructor(props, context) {
        super(props, context);
        // const windowScrollTop = this.props.scrollBar.scrollTop / 3;
        // this.state = {
        //     transform: "translate3d(0," + windowScrollTop + "px,0)"
        // };
    }

    componentDidMount() {
        const scrollBar = this.props.scrollBar;
        const windowScrollTop = scrollBar ? scrollBar.getScrollTop() / 3 : 0;
        this.setState({
            transform: "translate3d(0," + windowScrollTop + "px,0)"
        });
    }

    componentWillUnmount() {
    }

    resetTransform = () => {
        const windowScrollTop = this.props.scrollBar.getScrollTop() / 3;
        this.setState({
            transform: "translate3d(0," + windowScrollTop + "px,0)"
        });
    };

    render() {
        const {
            classes,
            filter,
            className,
            children,
            style,
            image,
            small
        } = this.props;
        const parallaxClasses = classNames({
            [classes.parallax]: true,
            [classes.filter]: filter,
            [classes.small]: small,
            [className]: className !== undefined
        });
        return (
            <div
                className={parallaxClasses}
                style={{
                    ...style,
                    backgroundImage: "url(" + image + ")",
                    ...this.state
                }}
            >
                {children}
            </div>
        );
    }
}

Parallax.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string,
    filter: PropTypes.bool,
    children: PropTypes.node,
    style: PropTypes.string,
    image: PropTypes.string,
    small: PropTypes.bool,
    scrollBar: PropTypes.object,
};

export default withStyles(parallaxStyle)(Parallax);
