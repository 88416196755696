/*!

=========================================================
* Material Kit React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import {createBrowserHistory} from "history";
import {Router, Route, Switch} from "react-router-dom";

import "assets/scss/material-kit-react.scss?v=1.7.0";

// pages for this product
import LandingPage from "views/LandingPage/LandingPage.jsx";
import Style from "./assets/jss/Style";
import ThemeProvider from "@material-ui/styles/ThemeProvider";

const hist = createBrowserHistory();

ReactDOM.render(
    <ThemeProvider theme={Style}>
        <Router history={hist}>
            <Switch>
                <Route path="/*" component={LandingPage}/>
            </Switch>
        </Router>
    </ThemeProvider>,
    document.getElementById("root")
);
