/*!

=========================================================
* Material Kit React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {createRef} from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons

// core components
import Header from "../../components/Header/Header.jsx";
import Footer from "../../components/Footer/Footer.jsx";
import GridContainer from "../../components/Grid/GridContainer.jsx";
import GridItem from "../../components/Grid/GridItem.jsx";
import Parallax from "../../components/Parallax/Parallax.jsx";

import landingPageStyle from "../../assets/jss/material-kit-react/views/landingPage.jsx";

import ProjectCard from "../../components/Card/ProjectCard";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import HeaderLinks from "../../components/Header/HeaderLinks";
import {Typography} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import { Scrollbars } from 'react-custom-scrollbars-2';
import { personalCards, xdaCards } from '../../util/Cards'

class LandingPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            headerHeight: 0,
        };
        this.header = createRef();
        this.headerWrapper = createRef();

        // eslint-disable-next-line no-unused-vars
        const headerSizeChange = this.headerSizeChange.bind(this);
    }

    headerSizeChange(size) {
        this.setState({
            headerHeight: size,
        })
    }

    componentDidMount() {

    }

    headerHeight() {
        const height = this.headerWrapper.current.clientHeight;
        console.error(height);
        return height;
    }

    onScroll() {
        this.parallax.resetTransform();
        this.header.current.headerColorChange();
    }

    render() {
        // eslint-disable-next-line no-unused-vars
        const {classes, ...rest} = this.props;

        const headerHeightStyle = {
            paddingTop: this.state.headerHeight,
            marginTop: -this.state.headerHeight
        }

        return (
            <Scrollbars
                ref={(scrollbar) => this.scrollBar = scrollbar}
                onScroll={() => this.onScroll()}
                style={{
                    width: '100vw',
                    height: '100vh'
                }}>
                <div>
                    <div ref={this.headerWrapper}
                    style={{display: 'inline'}}>
                        <Header
                            id={'header'}
                            color="transparent"
                            brand="Zachary Wander"
                            ref={this.header}
                            fixed
                            scrollBar={this.scrollBar}
                            changeColorOnScroll={{
                                height: 400,
                                color: "dark"
                            }}
                            onHeaderResize={this.headerSizeChange.bind(this)}
                            rightLinks={<HeaderLinks/>}
                            leftLinks={
                                <List className={classes.list}>
                                    <ListItem className={classes.listItem}>
                                        <Button
                                            className={classes.navLink}
                                            onClick={() =>
                                                document
                                                    .getElementById('personal-projects')
                                                    .scrollIntoView({behavior: 'smooth', block: 'start'})}>
                                            Personal Projects
                                        </Button>
                                    </ListItem>
                                    <ListItem className={classes.listItem}>
                                        <Button
                                            className={classes.navLink}
                                            onClick={() =>
                                                document
                                                    .getElementById('xda-projects')
                                                    .scrollIntoView({behavior: 'smooth', block: 'start'})}>
                                            XDA Projects
                                        </Button>
                                    </ListItem>
                                </List>
                            }
                            {...rest}
                        />
                    </div>
                    <Parallax
                        ref={(parallax) => this.parallax = parallax}
                        filter
                        scrollBar={this.scrollBar}
                        image={require("assets/img/landing-bg.webp").default}>
                        <div className={classes.container}>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={6}>
                                    <h1 className={classes.title}>Hello!</h1>
                                    <h4>{"This site is a collection of projects I've created. Click the cards below to learn more about each app, and check out the links above for ways to get in touch."}</h4>
                                    <br/>
                                </GridItem>
                            </GridContainer>
                        </div>
                    </Parallax>
                    <div className={classNames(classes.main, classes.mainRaised)}>
                        <div className={classes.container}>
                            <Container fluid={true}
                                       style={{
                                           paddingTop: '4em',
                                           paddingBottom: '4em',
                                       }}>
                                <Row>
                                    <Col>
                                        <Typography
                                            id={'personal-projects'}
                                            variant={'h4'}
                                            className={classes.title}
                                            style={headerHeightStyle}
                                        >
                                            Personal Projects
                                        </Typography>
                                    </Col>
                                </Row>
                                <Row>
                                    {personalCards.map((item, index) =>
                                        <Col xl={4} lg={6} key={item.name}>
                                            <ProjectCard
                                                match={this.props.match}
                                                index={index}
                                                name={item.name}
                                                shortDesc={item.shortDesc}
                                                fullDesc={item.fullDesc}
                                                playLink={item.playLink}
                                                patreonLink={item.patreonLink}
                                                xdaLink={item.xdaLink}
                                                githubLink={item.githubLink}
                                                downloadLink={item.downloadLink}
                                                amazonLink={item.amazonLink}
                                                icon={item.icon}
                                                background={item.background}
                                                />
                                        </Col>
                                    )}
                                </Row>
                                <Row
                                    style={{
                                        marginTop: '4em',
                                    }}>
                                    <Col>
                                        <Typography
                                            id={'xda-projects'}
                                            variant={'h4'}
                                            className={classes.title}
                                            style={{
                                                paddingTop: this.state.headerHeight,
                                                marginTop: -this.state.headerHeight
                                            }}
                                        >
                                            XDA Projects
                                        </Typography>
                                    </Col>
                                </Row>
                                <Row>
                                    {xdaCards.map((item, index) =>
                                        <Col xl={4} lg={6} key={item.name}>
                                            <ProjectCard
                                                match={this.props.match}
                                                index={index}
                                                name={item.name}
                                                shortDesc={item.shortDesc}
                                                fullDesc={item.fullDesc}
                                                playLink={item.playLink}
                                                patreonLink={item.patreonLink}
                                                xdaLink={item.xdaLink}
                                                githubLink={item.githubLink}
                                                downloadLink={item.downloadLink}
                                                amazonLink={item.amazonLink}
                                                icon={item.icon}
                                                background={item.background}
                                            />
                                        </Col>
                                    )}
                                </Row>
                            </Container>
                        </div>
                    </div>
                    <Footer/>
                </div>
            </Scrollbars>
        );
    }
}

LandingPage.propTypes = {
    classes: PropTypes.object,
    match: PropTypes.object
};

export default withStyles(landingPageStyle)(LandingPage);
